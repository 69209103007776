<template>
  <div class="s1">
    <div class="bx-wrapper">
      <kendo-data-source ref="datasource" :data="kendoDataSource"></kendo-data-source>
      <kendo-scroll-view
        :ref="'kendoScrollView'"
        :data-source-ref="'datasource'"
        :duration="1000"
        :contentHeight="'100%'"
        :template="`#=data.template#`"
        @change="onChange"
        v-bind:style="{ width: '100%;', background: '#eee' }"
      ></kendo-scroll-view>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted, onBeforeUnmount } from 'vue';
import { DataSource } from '@/utils/kendo/kendoDataSource';
import { ScrollView, getKendoScrollView } from '@/utils/kendo/kendoScrollView';

export default defineComponent({
  components: {
    'kendo-data-source': DataSource,
    'kendo-scroll-view': ScrollView
  },
  setup() {
    const data = reactive({
      timer: 0,
      kendoScrollView: null,
      kendoDataSource: [
        {
          template: `<li class="visual2">
          <img src="${require('@/assets/images/visual2.png')}" />
          <div class="tit" style="float: left; right: 37%">
            <p class="t1-1">정확하고 안전한 투명교정</p>
            <p class="t1-2"><strong>MagicAlign</strong></p>
          </div>
          <div class="tit" style="float: right; left: 37%">
            <p class="t1-1">고정형 하이브리드 보철</p>
            <p class="t1-2"><strong>Magic4</strong></p>
          </div>
        </li>`
        },
        {
          template: `<li class="visual3">
          <img src="${require('@/assets/images/visual3.png')}" />
          <div class="tit">
            <p class="t1">이제 당신도 교정 / 덴쳐 전문가<br /><strong>MagicLine</strong>이 함께합니다.</p>
            <p class="t2">
              3차원 CAD/CAM 기술로 정확한 진단 및 치료 결과를 시뮬레이션하여<br />
              정확한 치료계획 수립과 진료를 가능하게 합니다
            </p>
            <p align="center">
              <img src="${require('@/assets/images/portfolio.png')}" />
            </p>
          </div>
        </li>`
        },
        {
          template: `<li class="visual4">
          <img src="${require('@/assets/images/visual4.png')}" />
          <div class="tit">
            <p class="t1">Digital Dentistry의 시작!<br /><strong>MagicLine</strong></p>
            <p class="t2">MagicLine이 디지털치과의 토털 솔루션을 제공합니다</p>
          </div>
        </li>`
        },
        {
          template: `<li class="visual1">
          <img src="${require('@/assets/images/visual1.png')}" />
          <div class="tit">
            <p class="t1">연계 시술 시너지 효과<br /><strong>MagicLine</strong></p>
            <p class="t2">디지털교정 및 디지털덴쳐의 연계 시술로 진료 영역을 확대하세요</p>
            <p align="center">
              <img src="${require('@/assets/images/magic_portfolio.png')}" />
            </p>
          </div>
        </li>`
        }
      ]
    });

    const setTimer = (nextPage: number): void => {
      data.timer = setTimeout(() => getKendoScrollView(data.kendoScrollView).scrollTo(nextPage), 5000);
    };

    const clearTimer = (): void => clearInterval(data.timer);

    const onChange = (e: any): void => {
      const nextPage = (e.nextPage + 1) % data.kendoDataSource.length;
      clearTimer();
      setTimer(nextPage);
    };

    const onResize = (): void => getKendoScrollView(data.kendoScrollView).refresh();

    onMounted((): void => {
      setTimer(1);
      window.addEventListener('resize', onResize);
    });

    onBeforeUnmount((): void => {
      clearTimer();
      window.removeEventListener('resize', onResize);
    });

    const methods = { onChange, onResize };

    return {
      ...toRefs(data),
      ...methods
    };
  }
});
</script>

<style scoped lang="scss">
@import './Carousel.scss';
</style>
