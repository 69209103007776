
import { defineComponent, ref } from 'vue';

type Item = {
  src: any;
};

export default defineComponent({
  setup() {
    const items = ref<Array<Item>>([
      { src: require('@/assets/images/ortho1_1.png') },
      { src: require('@/assets/images/ortho2_1.png') },
      { src: require('@/assets/images/ortho3_1.png') },
      { src: require('@/assets/images/ortho5_1.png') },
      { src: require('@/assets/images/ortho4_1.png') },
      { src: require('@/assets/images/ortho6_1.png') },
      { src: require('@/assets/images/ortho9.png') }
    ]);

    return { items };
  }
});
