<template>
  <li :class="classList">
    <router-link :to="to">
      <div class="tit"></div>
      <div class="img"></div>
      <div class="lineup_card_text" v-html="text"></div>
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    classList: Array,
    to: String,
    text: String
  }
});
</script>

<style scoped lang="scss">
@import './LineupListItem.scss';
</style>
