<template>
  <div>
    <carousel></carousel>
    <lineup-list></lineup-list>
    <concept></concept>
    <process></process>
    <digital-solution></digital-solution>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Carousel from '@/components/main/Carousel.vue';
import Concept from '@/components/main/Concept.vue';
import DigitalSolution from '@/components/main/DigitalSolution.vue';
import LineupList from '@/components/main/LineupList.vue';
import Process from '@/components/main/Process.vue';

export default defineComponent({
  components: { Carousel, LineupList, Concept, Process, DigitalSolution }
});
</script>

<style scoped lang="scss">
@import './MainView.scss';
</style>
