<template>
  <div>
    <div class="s6">
      <div class="tit_area">
        <p class="t1">디지털치과 토털 솔루션 <strong>MagicLine</strong></p>
        <p class="t2">디지털 솔루션을 통해 고객의 성공을 견인합니다.</p>
      </div>
      <div class="ortho_img">
        <ul>
          <li v-for="(item, index) in items" :key="index">
            <transition appear appear-to-class="list-appear-to-class" appear-active-class="list-appear-active-class">
              <img :src="item.src" alt="" />
            </transition>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

type Item = {
  src: any;
};

export default defineComponent({
  setup() {
    const items = ref<Array<Item>>([
      { src: require('@/assets/images/ortho1_1.png') },
      { src: require('@/assets/images/ortho2_1.png') },
      { src: require('@/assets/images/ortho3_1.png') },
      { src: require('@/assets/images/ortho5_1.png') },
      { src: require('@/assets/images/ortho4_1.png') },
      { src: require('@/assets/images/ortho6_1.png') },
      { src: require('@/assets/images/ortho9.png') }
    ]);

    return { items };
  }
});
</script>

<style scoped lang="scss">
@import './DigitalSolution.scss';
</style>
