
import { defineComponent, reactive, toRefs } from 'vue';

export default defineComponent({
  setup() {
    const data = reactive({
      steps: [
        { src: require('@/assets/images/step1.png'), text: '주문접수' },
        { src: require('@/assets/images/step3.png'), text: '디자인/승인' },
        { src: require('@/assets/images/step4.png'), text: '장치제작/배송' },
        { src: require('@/assets/images/step5.png'), text: '진료' }
      ]
    });

    return { ...toRefs(data) };
  }
});
