
import { defineComponent } from 'vue';
import Carousel from '@/components/main/Carousel.vue';
import Concept from '@/components/main/Concept.vue';
import DigitalSolution from '@/components/main/DigitalSolution.vue';
import LineupList from '@/components/main/LineupList.vue';
import Process from '@/components/main/Process.vue';

export default defineComponent({
  components: { Carousel, LineupList, Concept, Process, DigitalSolution }
});
