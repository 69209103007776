<template>
  <div class="com_concept">
    <div class="cell-container">
      <div class="title_area">
        <p class="t2">{{ title }}</p>
      </div>
      <div class="t3">
        <div class="sec" v-for="(item, index) in items" :key="index">
          <div class="txt-wrap">
            <div class="tit">{{ item.title }}</div>
            <div class="hline"></div>
            <div class="txt">{{ item.message }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

type Item = {
  title: string;
  message: string;
};

export default defineComponent({
  setup() {
    const title = ref<string>('마법처럼 놀라운 MagicLine');
    const items = ref<Array<Item>>([
      { title: 'PRECISE', message: '우수한 적합도' },
      { title: 'EASY', message: '접수부터 진료까지 간편하게' },
      { title: 'SPEEDY', message: '내원횟수 및 체어타임 감소' }
    ]);

    return { title, items };
  }
});
</script>

<style scoped lang="scss">
@import './Concept.scss';
</style>
