
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    classList: Array,
    to: String,
    text: String
  }
});
