
import { defineComponent, ref } from 'vue';

type Item = {
  title: string;
  message: string;
};

export default defineComponent({
  setup() {
    const title = ref<string>('마법처럼 놀라운 MagicLine');
    const items = ref<Array<Item>>([
      { title: 'PRECISE', message: '우수한 적합도' },
      { title: 'EASY', message: '접수부터 진료까지 간편하게' },
      { title: 'SPEEDY', message: '내원횟수 및 체어타임 감소' }
    ]);

    return { title, items };
  }
});
