<template>
  <div class="s4">
    <div class="title_area">
      <p class="t1">접수부터 진료까지 <strong>간편하게</strong></p>
      <p class="t2">쉽고 빠른 접수 및 확인으로 효율적인 치과 진료를 도와드립니다.</p>
    </div>
    <div class="step_area">
      <ul>
        <li v-for="(step, index) in steps" :key="index">
          <img :src="step.src" alt="" />
          <span class="txt">{{ step.text }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';

export default defineComponent({
  setup() {
    const data = reactive({
      steps: [
        { src: require('@/assets/images/step1.png'), text: '주문접수' },
        { src: require('@/assets/images/step3.png'), text: '디자인/승인' },
        { src: require('@/assets/images/step4.png'), text: '장치제작/배송' },
        { src: require('@/assets/images/step5.png'), text: '진료' }
      ]
    });

    return { ...toRefs(data) };
  }
});
</script>

<style scoped lang="scss">
@import './Process.scss';
</style>
