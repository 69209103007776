<template>
  <div>
    <div class="s2">
      <div class="title_area">
        <p class="t2">{{ title }}</p>
      </div>
      <ul class="mg_line_info">
        <lineup-list-item
          v-for="(item, index) in items"
          :key="index"
          :classList="item.classList"
          :src="item.src"
          :to="item.to"
          :text="item.text"
        />
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from '@/store/index';
import LineupListItem from '../main/LineupListItem.vue';

export default defineComponent({
  components: { LineupListItem },
  setup() {
    const store = useStore();
    const { ORTHODONTICS, DENTURES } = store.state.resources;
    const title = 'MagicLine 라인업';
    const items = [
      {
        classList: ['correct'],
        to: ORTHODONTICS.subPath.MAGIC_ALIGN.fullPath,
        text: '<div>빠른 치료 기간<br />투명하고 편안한 착용감</div>'
      },
      {
        classList: ['pos'],
        to: ORTHODONTICS.subPath.MAGIC_POSITIONER.fullPath,
        text: '<div>빠르고 정확한<br />브라켓 본딩</div>'
      },
      // {
      //   classList: ['ret'],
      //   to: ORTHODONTICS.subPath.MAGIC_RETAINER.fullPath,
      //   text: '<div>NiTi 소재의 견고한<br />치열 유지장치</div>'
      // },
      {
        classList: ['dt4'],
        to: DENTURES.subPath.MAGIC_4.fullPath,
        text: '<div>완전무치악 환자의<br />고정형 임플란트 보철</div>'
      }
    ];

    return { title, items };
  }
});
</script>

<style scoped lang="scss">
@import './LineupList.scss';
</style>
